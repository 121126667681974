import React from 'react';
import '../main.css';
import './project.css';
import '../media.css'
import Modal from '../modal/modal'
import Preloader from '../preloader';
import '../preloader.css'
import firstImg from '../img/4.png';
import secondImg from '../img/5.png';
import thirdImg from '../img/6.png';
import * as main from '../main.js'
import  {WrapImg} from '../Img'



import {Route, Link, Routes} from 'react-router-dom'
import aboutImg from "../img_screen/ox.png";

class SecondWork extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            maxHeight: 0,
            maxHeightChCont:0,
            maxHeightChProj:0,
            isToggleOn:false,
            toggleChild:false,
            toggleChildCont: false,
            deployList: false,
            color:"white",
            overflow: null,
            count:0,
            countP: 0,
            countC: 0,
            rotate: 0,
            rotateTextArrow: 0,
            resX: 1,
            resY:1,
            windowHeight:undefined,
            windowWidth:undefined,
            reload:false,
            paddingRightProj:0,
            closeViewer: false,
            opacity: 0.0,
            className:" ",
            display:"none",
            nextFrame:0,
            prevFrame:0,
            arr : [firstImg,secondImg,thirdImg],
            textArr: ["img_01","img_02","img_03"],
            isNew:true,
            index:0,
            title:"breathe",
            deployText: false,
            textMaxHeight: 100,
            idx:0,
            searchResult:[],
            searchResultLinks:[],
            opacitySearchList: 0.0,
            transparent:true,
            transparentMenu: true,
            transparentAbout: false,
            isOpenAbout: false

        };
        this.toggleChildPanelProj = this.toggleChildPanelProj.bind(this);
        this.toggleChildPanelCont = this.toggleChildPanelCont.bind(this);
        this.deployListOnClick = this.deployListOnClick.bind(this);
        this.deployTextOnClick = this.deployTextOnClick.bind(this);
        this.handleRes = this.handleRes.bind(this);
        this.handlePop = this.handlePop.bind(this);
        this.interValRes = this.interValRes.bind(this);

    }


    deployTextOnClick = (e) => {
        this.setState((state)=>{
            return{
                deployText:!state.deployText,
            }
        });
        const el =  document.getElementsByClassName("aboutProjText")[0];
        const header =  document.getElementsByClassName("nameProj")[0];
        let offsetHeader = header.getBoundingClientRect().height;
        let offset = el.clientHeight+offsetHeader;
        //console.log(offset);

        if(this.state.deployText){
            this.setState((state)=>{
                return{
                    textMaxHeight: 100,
                    rotateTextArrow: 0+"deg"
                }
            });
        }else{
            this.setState((state)=>{
                return{
                    textMaxHeight: (offset),
                    rotateTextArrow:180+"deg"
                }
            });
        }
        //console.log(this.state.textMaxHeight)
    }

    deployListOnClick = (e) => {
        //+++++++++++++++++++++++++++delete++++++++++++++++++++
        //this.setState({deployList:!this.state.deployList});
        //e.preventDefault();
        this.setState((state)=>{
            return{
                deployList:!state.deployList,
            }
        });



        /*this.setState(prevState => ({
            deployList: !prevState.deployList
        }));*/

        const el = document.getElementsByClassName("itemPages");
        const el2 = document.getElementsByClassName("projectsItem");
        const el3 = document.getElementsByClassName("contactsItem");
        let offsetTemp = (el[0].scrollHeight*el.length)+(el2[0].scrollHeight*el2.length)+(el3[0].scrollHeight*el3.length);
        //console.log(offsetTemp)
        //let num = el.length+el2.length+el3.length+1;
        //const offset = e.target.scrollHeight*num;
        if(this.state.deployList){
            this.setState({
                maxHeight:0,
                rotate:0+"deg"
            });
            setTimeout(() => {
                this.setState({ transparentMenu: true });
            }, 200);

        }else{
            this.setState((state)=>{
                return{
                    maxHeight: (state.maxHeight+offsetTemp),
                    rotate:180+"deg"
                }
            });
            setTimeout(() => {
                this.setState({ transparentMenu: false });
            }, 200);
        }



        //console.log("offset: " + offset);
    };


    toggleChildPanelProj = (e) => {
        //+++++++++++++++++++++++++++delete++++++++++++++++++++
        //this.setState({toggleChild:!this.state.toggleChild});
        this.setState((state)=>{
            return{
                toggleChild:!state.toggleChild,
            }
        });
        const el = document.getElementsByClassName("projectsItem");
        const offset = e.target.scrollHeight*el.length;

        if(this.state.toggleChild){
            this.setState({maxHeightChProj:null});
        }else if(!this.state.toggleChild){
            this.setState({maxHeightChProj:offset+"px"});
        }
    };

    toggleChildPanelCont = (e) => {
        //+++++++++++++++++++++++++++delete++++++++++++++++++++
        //this.setState({toggleChildCont:!this.state.toggleChildCont});
        this.setState((state)=>{
            return{
                toggleChildCont:!state.toggleChildCont,
            }
        });
        const el = document.getElementsByClassName("contactsItem");
        const offset = e.target.scrollHeight*el.length;

        if(this.state.toggleChildCont){
            this.setState({maxHeightChCont:null});
        }else{
            this.setState({maxHeightChCont:offset+"px"});
        }
    };

    interValRes =()=>{
        let el = document.getElementsByClassName("vidWrap")[0];
        setInterval(()=> {
            this.setState({
                resX:el.clientWidth,
                resY:el.clientHeight,
            });
        },1000);

    };

    handleRes = () => {
        if(document.readyState === 'complete'){
            let el = document.getElementsByClassName("vidWrap")[0];
            this.setState({
                resX:el.clientWidth,
                resY:el.clientHeight,
            });
        }
        this.handlePop();

    };



    handlePop = () => { // создали новый метод, в котором вызываем forceUpdate. Этот метод мы установили в качестве обработчика выше
        this.forceUpdate()
    };

    closeModal = () =>{
        let fadeOut = setInterval(()=>{
            if(this.state.opacity<0.01){
                this.setState({closeViewer:false});
                clearInterval(fadeOut)
            }
            this.setState((state)=>{
                return{
                    opacity: state.opacity - 0.01,
                }
            })
        },5);

    };

    nexFrame=()=>{

        if(this.state.nextFrame!==this.state.arr.length-1){
            this.setState((state)=>{
                return{
                    nextFrame:state.nextFrame+1,
                }
            });
            //console.log(this.state.nextFrame)
        }else{
            this.setState((state)=>{
                return{
                    nextFrame: 0,
                }
            })
        }
        // console.log(this.state.nextFrame);
        this.handleClick();

    };
    prevFrame=()=>{
        if(this.state.nextFrame>0){
            this.setState((state)=>{
                return{
                    nextFrame:state.nextFrame-1
                }
            });
        }else{
            this.setState((state)=>{
                return{
                    nextFrame:state.arr.length-1
                }
            });
        }
        this.handleClick();

    };

    handleClick=()=>{
        this.setState({isNew:!this.state.isNew});
        if(this.state.isNew===true){
            let up = setInterval(()=>{
                this.setState({isNew:true});
            },500);
            // clearInterval(up);
        }
    };

    openModal=(e)=>{
        let fadeIn = setInterval(()=>{
            if(this.state.opacity>=1){
                clearInterval(fadeIn)
            }
            this.setState((state)=>{
                return{
                    opacity: state.opacity + 0.01,
                    closeViewer: true,
                    display: "block"
                }
            })
        },5);

        let idx = parseInt(e.target.getAttribute('idx'));

        if(idx!==this.state.arr.length){
            this.setState((state)=>{
                return{
                    nextFrame: idx
                }
            });
        }else{
            this.setState((state)=>{
                return{
                    nextFrame: 0
                }
            });
        }

    };

    searchingList=(e)=>{
        const elem = document.getElementsByClassName("pages")[0];
        let inputValue = e.target.value.toLowerCase();

        const itemsLength = elem.childNodes.length
        let arrPages = [];
        let linkArr = [];
        let sectionArr = [];

        if(inputValue!==''){
            setTimeout(() => {
                this.setState({ transparent: false });
            }, 200);
        }else{
            setTimeout(() => {
                this.setState({ transparent: true });
            }, 200);
        }

        for(let i = 0; i < itemsLength;i++){
            arrPages.push(elem.childNodes.item(i));
            if(arrPages[i].hasChildNodes()){
                linkArr.push(arrPages[i].lastChild.href);
                sectionArr.push(arrPages[i].lastChild.text)
            }

            if(arrPages[i].childNodes.length > 1) {
                if(arrPages[i].childNodes.item(1).hasChildNodes()){
                    let size = arrPages[i].lastChild.childElementCount;
                    for(let j = 0; j < size;j++){
                        linkArr.push(arrPages[i].lastChild.childNodes.item(j).childNodes.item(0).href);
                        sectionArr.push(arrPages[i].lastChild.childNodes.item(j).childNodes.item(0).text)
                    }
                }
            }
        }

        const outLinkArr = linkArr.filter(element => {
            return element !== undefined;
        });
        const outSectionArr = sectionArr.filter(element => {
            return element !== undefined;
        });
        let list = new Map();
        for(let i = 0; i < elem.childNodes.length;i++){
            list.set(outSectionArr[i],outLinkArr[i]);
        }
        const searchFilter = outSectionArr.filter((element,index) =>{
            if(element.includes(inputValue) && inputValue !== ''){
                return element;
            }
        });

        let linksTemp = [];
        for(let el of searchFilter){
            linksTemp.push(list.get(el));
        }

        this.setState({
            searchResult: searchFilter,
            searchResultLinks: linksTemp
        });


        //console.log(this.state.searchResultLinks.get(inputValue))
        //console.log(this.state.searchResultLinks)

    }

    toggleAbout=()=>{

        this.setState((state)=>{
            return{
                isOpenAbout:!state.isOpenAbout,
            }
        });
        if(this.state.isOpenAbout){
            setTimeout(() => {
                this.setState({ transparentAbout: false });
            }, 200);
        }else{
            setTimeout(() => {
                this.setState({ transparentAbout: true });
            }, 200);
        }

    }



    componentDidMount() {
        this.interValRes();
        window.addEventListener('popstate', this.handleRes);
        document.addEventListener('DOMContentLoaded',this.handleRes);
        window.addEventListener('load',this.handleRes);
        window.addEventListener('resize',this.handleRes);

    }

    componentWillUnmount() {
        clearInterval(this.interValRes);
    }


    render(){
        // const title = "modalTest";
        const breakLine  = "\n";
        return(

            <div className ="mainProj">
                <Preloader/>
                <div className="mainWrap">
                    <div className="wrapper">
                        <h1 className="header">DMITRY KORNIENKO</h1>
                        <div className="searchContainer">
                            {/*<p className="search">search</p>*/}
                            <form>
                                <input onChange={(e)=>this.searchingList(e)} autoComplete="off" className="search" type="text" name="itemNavigation" placeholder="find something.."/>
                            </form>
                            <div className="searchList" >
                                {
                                    this.state.searchResult.map((item,key)=>{
                                        return <a key={key} style={{opacity: this.state.transparent ? '0.0' : '1.0' }}  href={this.state.searchResultLinks[key]} className="searchRef">{item}</a>;
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="wrapper">
                        <nav className="navigation">
                            <div className="wrapper">
                                <button className="arrow" onClick={(e)=>this.deployListOnClick(e)} style ={{transform: `rotate(${this.state.rotate})`}}>
                                    <svg id="39d3585c-6493-4962-8551-4e8e9da4fd73" data-name="Layer 2"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                        <title>Untitled-2</title>
                                        <path d="M27.33,119.88,146,238.59a5.59,5.59,0,0,0,7.92,0L272.67,119.88a5.38,5.38,0,0,0,1.05-1.45L299,67.93c2.85-5.69-4.48-11-9-6.47l-136,136a5.59,5.59,0,0,1-7.92,0L10,61.46c-4.49-4.5-11.82.78-9,6.47l25.25,50.5A5.38,5.38,0,0,0,27.33,119.88Z" fill="#fff"/>
                                    </svg>
                                    {/*{this.state.deployList?"on":"off"}*/}
                                </button>
                                <ul className="listNavigation" >
                                    <li className= "itemNavigation">
                                        menu
                                        <ul className="pages" style={{maxHeight:this.state.maxHeight+"px",opacity: this.state.transparentMenu ? '0.0' : '1.0' }}>
                                            <li className="itemPages"><Link to="/" className="itemPagesLink">home</Link></li>
                                            <li className="itemPages" onClick={(e)=>this.toggleChildPanelProj(e)} >projects/
                                                <ul className="projectsList" style={{maxHeight:this.state.maxHeightChProj}}>
                                                    <li className="projectsItem">
                                                        <Link to="/landscape" className="itemPagesLinkProj">landscape_0x1</Link>
                                                    </li>
                                                    <li className="projectsItem">
                                                        <Link to="/breathe" className="itemPagesLinkProj">breathe</Link>
                                                    </li>
                                                    <li className="projectsItem">
                                                        <Link to="/intervals2023" className="itemPagesLinkProj">intervals2023</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="itemPages"><Link to="/cv" className="itemPagesLink">cv</Link></li>
                                            <li className="itemPages" onClick={(e)=>this.toggleChildPanelCont(e)}>contacts/
                                                <ul className="contactsList" style={{maxHeight:this.state.maxHeightChCont}}>
                                                    <li className="contactsItem"><a href="mailto:martmartmart53@gmail.com" className="itemPagesLinkProj">mail</a></li>
                                                    <li className="contactsItem"><a href="https://www.facebook.com/mart.mai.1/" className="itemPagesLinkProj">facebook</a></li>
                                                    <li className="contactsItem"><a href="https://www.instagram.com/martmaimaimart/" className="itemPagesLinkProj">instagram</a></li>
                                                </ul>
                                            </li>
                                            <li className="itemPages" onClick={()=>this.toggleAbout()}>about</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                        <Routes>
                            <Route path="/home" component={main.Main}/>
                        </Routes>


                        <section className="screenProj">

                            <div className="mainFrame" style={{display:this.state.isOpenAbout?'none':'flex',opacity:this.state.transparentAbout?'0.0':'1.0'}}>
                                <div className="wrapper">
                                    <div className="vidWrap">
                                        <div className="embed-container">
                                            <iframe title="frame" className="mainImg"
                                                    src="https://www.youtube.com/embed/SMVQZnaAXkg?controls=0&color=white&vq=hd1080"
                                                    title="YouTube video player" frameBorder="0"
                                                    controls="0"

                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"

                                                    allowFullScreen/>
                                        </div>
                                        <script src="https://www.youtube.com/iframe_api"/>
                                    </div>

                                    <div className="wrapper">
                                        <div className="emptyBlock"
                                             style={{width: this.state.resX, height: this.state.resY}}/>
                                    </div>
                                    <div className="colWrap">
                                        <div className="wrapper">
                                            <WrapImg idx={"0"} className="firstImg" onClick={(e) => this.openModal(e)}
                                                     alt={"firstImg"} src={firstImg}/>
                                            {/*<img className="firstImg"  id="0" onClick={(e)=>this.openModal(e)} alt={"firstImg"} src={firstImg}/>*/}
                                            <WrapImg idx={"1"} className="firstImg" onClick={(e) => this.openModal(e)}
                                                     alt={"secondImg"} src={secondImg}/>
                                            {/*<img className="firstImg" id="1" onClick={(e)=>this.openModal(e)} alt={"secondImg"} src={secondImg}/>*/}
                                        </div>
                                        <WrapImg idx={"2"} className="thirdImg" onClick={(e) => this.openModal(e)}
                                                 alt={"thirdImgImg"} src={thirdImg}/>
                                    </div>
                                </div>
                            </div>
                            {!this.state.isOpenAbout &&
                                <button className="arrowText" onClick={(e) => this.deployTextOnClick(e)}
                                        style={{opacity:this.state.transparentAbout?'0.0':'1.0',transform: `rotate(${this.state.rotateTextArrow})`}}>
                                    <svg id="39d3585c-6493-4962-8551-4e8e9da4fd73" data-name="Layer 2"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                        <title>Untitled-2</title>
                                        <path
                                            d="M27.33,119.88,146,238.59a5.59,5.59,0,0,0,7.92,0L272.67,119.88a5.38,5.38,0,0,0,1.05-1.45L299,67.93c2.85-5.69-4.48-11-9-6.47l-136,136a5.59,5.59,0,0,1-7.92,0L10,61.46c-4.49-4.5-11.82.78-9,6.47l25.25,50.5A5.38,5.38,0,0,0,27.33,119.88Z"
                                            fill="#fff"/>
                                    </svg>
                                </button>
                            }
                            {!this.state.isOpenAbout &&
                                <div className="info" style={{maxHeight: this.state.textMaxHeight + "px",opacity:this.state.transparentAbout?'0.0':'1.0'}}>
                                    <h2 className="nameProj">BREATHE</h2>
                                    <p className="aboutProjText">
                                        Soon...............
                                    </p>
                                </div>
                            }
                            {this.state.isOpenAbout &&
                                <div className="aboutScreen" style={{opacity:this.state.transparentAbout?'1.0':'0.0'}}>
                                    <div className="aboutScreenWrapperProj">
                                        <img alt="o/x" className="aboutImg" src={aboutImg}/>
                                        <p className="textMarquee">© 2020 DMITRY KORNIENKO. All rights reserved.</p>
                                    </div>
                                </div>
                            }
                        </section>
                    </div>
                    <div className="wrapper">
                        <div className="offsetWrap">
                            <p className="textProj">project::breathe/page_breathe </p>
                            <div className="rectProj"></div>
                        </div>
                    </div>
                </div>

                <Modal title={this.state.title} display={this.state.display}
                       isNew={this.state.isNew} next={(e)=>this.nexFrame(e)} previous={()=>this.prevFrame()}
                       opacity={this.state.opacity}  isOpen={this.state.closeViewer}
                       children={this.state.arr[this.state.nextFrame]} textChild={this.state.textArr[this.state.nextFrame]}
                       onCancel={()=>this.closeModal()}>
                </Modal>
            </div>

        );
    }
}

export {SecondWork};
