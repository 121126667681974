import React from "react";
import './preloader.css'
import Portal from "../src/modal/portal";


export default class Preloader extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isLoad:true
        };
        this.viewRef = React.createRef();
    }

    authenticate(){
        return new Promise(resolve => setTimeout(resolve, 1000))
    }

    loading=()=>{
        document.addEventListener("onload",()=>{
            this.setState({isLoad:false});
        });

    };
    componentDidMount() {
        // const show=()=> loader.classList.remove("preloader");
        /*window.addEventListener("DOMContentLoaded",()=>{
            this.setState({isLoad:false});
        })*/
        // this.props.hideLoader();
        // this.loading();
        //demoAsyncCall().then(() => this.setState({ isLoad: false }));


        this.authenticate().then(() => {
            const ele = document.getElementsByClassName('preloader')[0];
            if(ele){
                // fade out
                // ele.classList.add('available');
                setTimeout(() => {
                    // remove from DOM
                    //ele.outerHTML = '';
                    this.viewRef.current.style.opacity = 0;
                    this.viewRef.current.style.visibility ="hidden";
                }, 1000)
            }
        })

    }

    componentWillUnmount() {
        clearInterval(this.authenticate());
        // alert(document.readyState);
        /*document.removeEventListener('onload',()=>{

        })*/
    }

    render() {

        return(
            <Portal>
                <div className="preloader" ref={this.viewRef}>
                    <div className="wrapPreLoad">
                        <div className="preloadIcon">
                            <svg className="iconPreSvg" id="0f522b64-45d3-4133-afef-270788022991" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><title>close</title><polygon points="274.84 250.15 175.4 150.92 300.4 25.66 250.98 25.84 150.63 126.2 24.17 0 24.35 49.43 125.88 150.95 0.34 276.51 50.25 276.33 150.65 175.72 275.02 300.07 274.84 250.15" fill="#fff"/></svg>
                            <p className="loadingText">loading</p>
                        </div>
                    </div>

                </div>
            </Portal>
        )
    }

}

function demoAsyncCall() {
    return new Promise((resolve) => setTimeout(() => resolve(), 2500));
}
