import { keyframes} from "styled-components";
import styled from 'styled-components'
import React from "react";



const offset =(e)=> {

    let num = parseInt(e);
    //console.log(e)
    return keyframes`
        from {
            left:0;
        }

        to {
            left: ${num}%;
        }`
        ;
}

// Here we create a component that will rotate everything we pass in over two seconds
const OffsetRect = styled.div`
    position: absolute;
    left: 0px;
    top:auto;
    bottom: 0;
    right: auto;
    width: 60px;
    height: 4px;
    margin:0;
    padding:0;
    animation: ${props=>offset(props.offset)} ${props => props.time}s linear infinite;
    background-color: #ffffff;
`;

function RectTimeline(e){
    let off = e.offset;
    let t = e.time;

    return <OffsetRect offset={off} time={t}/>
}

export {RectTimeline};
export default offset;

