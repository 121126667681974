import React from 'react';
import './main.css';
import './img/background.jpg'
import vid from './vids/cooper_seg_pyr.mp4'
import aboutImg from './img_screen/ox.png'
import './media.css'
import {Link} from 'react-router-dom'
import Preloader from "./preloader";
import {RectTimeline} from './rectTimeLine'

import offset from "./rectTimeLine";
import Marquee from "react-fast-marquee";


class Main extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            maxHeight: 0,
            maxHeightChCont:0,
            maxHeightChProj:0,
            isToggleOn:false,
            toggleChild:false,
            toggleChildCont: false,
            deployList: false,
            color:"white",
            overflow: null,
            count:0,
            countP: 0,
            countC: 0,
            rotate: 0,
            currentTimeVideo: 0.0,
            durationVideo:0.0,
            isLoad: false,
            persentOffset:0,
            searchResult:[],
            searchResultLinks:[],
            opacitySearchList: 0.0,
            transparent:true,
            transparentMenu: true,
            transparentAbout: false,
            isOpenAbout: false,
            headerWidth:0

        };

        this.togglePanel = this.togglePanel.bind(this);
        this.toggleChildPanelProj = this.toggleChildPanelProj.bind(this);
        this.toggleChildPanelCont = this.toggleChildPanelCont.bind(this);
        this.deployListOnClick = this.deployListOnClick.bind(this);



    }

    deployListOnClick = (e) => {
        //+++++++++++++++++++++++++++delete++++++++++++++++++++
        //this.setState({deployList:!this.state.deployList});
        //e.preventDefault();
        this.setState((state)=>{
            return{
                deployList:!state.deployList,
            }
        });



        /*this.setState(prevState => ({
            deployList: !prevState.deployList
        }));*/

        const el = document.getElementsByClassName("itemPages");
        const el2 = document.getElementsByClassName("projectsItem");
        const el3 = document.getElementsByClassName("contactsItem");
        let offsetTemp = (el[0].scrollHeight*el.length)+(el2[0].scrollHeight*el2.length)+(el3[0].scrollHeight*el3.length);
        //console.log(offsetTemp)
        //let num = el.length+el2.length+el3.length+1;
        //const offset = e.target.scrollHeight*num;
        if(this.state.deployList){
            this.setState({
                maxHeight:0,
                rotate:0+"deg"
            });
            setTimeout(() => {
                this.setState({ transparentMenu: true });
            }, 200);

        }else{
            this.setState((state)=>{
                return{
                    maxHeight: (state.maxHeight+offsetTemp),
                    rotate:180+"deg"
                }
            });
            setTimeout(() => {
                this.setState({ transparentMenu: false });
            }, 200);
        }



        //console.log("offset: " + offset);
    };

    togglePanel(e){
        this.setState({isToggleOn:!this.state.isToggleOn});
    }


    toggleChildPanelProj = (e) => {
        //+++++++++++++++++++++++++++delete++++++++++++++++++++
        //this.setState({toggleChild:!this.state.toggleChild});

        this.setState((state)=>{
            return{
                toggleChild:!state.toggleChild,
            }
        });
        const el = document.getElementsByClassName("projectsItem");
        const offset = e.target.scrollHeight*el.length;

        if(this.state.toggleChild){
            this.setState({maxHeightChProj:null});
        }else if(!this.state.toggleChild){
            this.setState({maxHeightChProj:offset+"px"});
        }
    };

    toggleChildPanelCont = (e) => {
        //+++++++++++++++++++++++++++delete++++++++++++++++++++
        //this.setState({toggleChildCont:!this.state.toggleChildCont});
        this.setState((state)=>{
            return{
                toggleChildCont:!state.toggleChildCont,
            }
        });
        const el = document.getElementsByClassName("contactsItem");
        const offset = e.target.scrollHeight*el.length;



        if(this.state.toggleChildCont){
            this.setState({maxHeightChCont:null});
        }else{
            this.setState({maxHeightChCont:offset+"px"});
        }


    };



    handleVideoMounted =(e) => {

        let duration = e.target.duration;
        let currentTimeVariable = e.target.currentTime;

        let remap = ((1/duration)*currentTimeVariable)*100;


        let elem = document.getElementsByClassName("bottomLine")[0]
        let vidElem = document.getElementsByClassName("video")[0]
        let widthElem = elem.offsetWidth;
        let lengthRect = 30;
        /*let a = false;
        vidElem.oncanplay = function() {
            a = true;
        };*/

        let widthWithoutElem = widthElem-lengthRect;
        let percent = (widthWithoutElem*100)/widthElem;


        //console.log(remap);
        this.setState({currentTimeVideo:remap});
        this.setState({durationVideo:duration});
        this.setState({persentOffset:percent});
        if(remap>=percent){
            this.setState({currentTimeVideo:0});
            //this.setState({durationVideo:0});
            this.setState({persentOffset:0});
            //this.setState({currentTimeVideo:0});
        }
        offset(remap);

    };



    handleOnLoad=(e)=>{
        //console.log(e.target.played.length)
        this.setState({isLoad:!this.state.isLoad})

    };
    binarySearch=(arr, key)=>{
        let start = 0;
        let end = arr.length-1;

        while(start <= end){
            let middle = Math.floor((start+end)/2);
            if(arr[middle] === key) return middle;
            if(arr[middle] < key){
                start = middle+1;
            }else{
                end = middle-1;
            }
        }
        return -1;
    }
    componentDidMount() {
        /*const el = document.getElementsByClassName("aboutContainer")[0]
        this.setState({
            headerWidth:el.clientWidth
        })*/

    }

    searchingList=(e)=>{
        const elem = document.getElementsByClassName("pages")[0];
        let inputValue = e.target.value.toLowerCase();


        const itemsLength = elem.childNodes.length
        let arrPages = [];
        let linkArr = [];
        let sectionArr = [];

        if(inputValue!==''){
            setTimeout(() => {
                this.setState({ transparent: false });
            }, 200);
        }else{
            setTimeout(() => {
                this.setState({ transparent: true });
            }, 200);
        }

        for(let i = 0; i < itemsLength;i++){
            arrPages.push(elem.childNodes.item(i));
            if(arrPages[i].hasChildNodes()){
                linkArr.push(arrPages[i].lastChild.href);
                sectionArr.push(arrPages[i].lastChild.text)
            }

            if(arrPages[i].childNodes.length > 1) {
                if(arrPages[i].childNodes.item(1).hasChildNodes()){
                    let size = arrPages[i].lastChild.childElementCount;
                    for(let j = 0; j < size;j++){
                        linkArr.push(arrPages[i].lastChild.childNodes.item(j).childNodes.item(0).href);
                        sectionArr.push(arrPages[i].lastChild.childNodes.item(j).childNodes.item(0).text)
                    }
                }
            }
        }

        const outLinkArr = linkArr.filter(element => {
            return element !== undefined;
        });
        const outSectionArr = sectionArr.filter(element => {
            return element !== undefined;
        });
        let list = new Map();
        for(let i = 0; i < elem.childNodes.length;i++){
            list.set(outSectionArr[i],outLinkArr[i]);
        }
        const searchFilter = outSectionArr.filter((element,index) =>{
            if(element.includes(inputValue) && inputValue !== ''){
                return element;
            }
        });

        let linksTemp = [];
        for(let el of searchFilter){
            linksTemp.push(list.get(el));
        }

        this.setState({
            searchResult: searchFilter,
            searchResultLinks: linksTemp
        });


        //console.log(this.state.searchResultLinks.get(inputValue))
        //console.log(this.state.searchResultLinks)

    }
    toggleAbout=()=>{
        this.setState((state)=>{
            return{
                isOpenAbout:!state.isOpenAbout,
            }
        });
        if(this.state.isOpenAbout){
            setTimeout(() => {
                this.setState({ transparentAbout: false });
            }, 200);
        }else{
            setTimeout(() => {
                this.setState({ transparentAbout: true });
            }, 200);
        }

    }

    render(){
        const markqueeStyle = {
            backgroundColor:"rgba(255, 255, 255, 0.0)",
            margin:"20px 0 0 0",
            //borderBottom: "1px solid #ffffff",
            //borderTop: "1px solid #ffffff"
        }


        return(
            <div className ="main">
                <Preloader/>
                <div className="mainWrap">
                    <div className="wrapper">
                        <h1 className="header">DMITRY KORNIENKO</h1>
                        <div className="searchContainer">
                            {/*<p className="search">search</p>*/}
                            <form>
                                <input onChange={(e)=>this.searchingList(e)} autoComplete="off" className="search" type="text" name="itemNavigation" placeholder="find something.."/>
                            </form>
                            <div className="searchList" >
                                {
                                    this.state.searchResult.map((item,key)=>{
                                        return <a key={key} style={{opacity: this.state.transparent ? '0.0' : '1.0' }}  href={this.state.searchResultLinks[key]} className="searchRef">{item}</a>;
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="wrapper">
                        <nav className="navigation">
                            <div className="wrapper">
                                <button className="arrow"  onClick={(e)=>this.deployListOnClick(e)} value={!this.state.deployList} style ={{transform: `rotate(${this.state.rotate})`}}>
                                    <svg id="39d3585c-6493-4962-8551-4e8e9da4fd73" data-name="Layer 2"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                        <title>Untitled-2</title>
                                        <path d="M27.33,119.88,146,238.59a5.59,5.59,0,0,0,7.92,0L272.67,119.88a5.38,5.38,0,0,0,1.05-1.45L299,67.93c2.85-5.69-4.48-11-9-6.47l-136,136a5.59,5.59,0,0,1-7.92,0L10,61.46c-4.49-4.5-11.82.78-9,6.47l25.25,50.5A5.38,5.38,0,0,0,27.33,119.88Z" fill="#fff"/>
                                    </svg>

                                </button>
                                <ul className="listNavigation" >
                                    <li className= "itemNavigation">
                                        menu
                                        <ul className="pages" style={{maxHeight:this.state.maxHeight+"px",opacity: this.state.transparentMenu ? '0.0' : '1.0' }}>
                                            <li className="itemPages"><Link to="/" className="itemPagesLink">home</Link></li>
                                            <li className="itemPages" onClick={(e)=>this.toggleChildPanelProj(e)} >projects/
                                                <ul className="projectsList" style={{maxHeight:this.state.maxHeightChProj}}>
                                                    <li className="projectsItem">
                                                        <Link to="/landscape" className="itemPagesLinkProj">landscape_0x1</Link>
                                                    </li>
                                                    <li className="projectsItem">
                                                        <Link to="/breathe" className="itemPagesLinkProj">breathe</Link>
                                                    </li>
                                                    <li className="projectsItem">
                                                        <Link to="/intervals2023" className="itemPagesLinkProj">intervals2023</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="itemPages"><Link to="/cv" className="itemPagesLink">cv</Link></li>
                                            <li className="itemPages" onClick={(e)=>this.toggleChildPanelCont(e)}>contacts/
                                                <ul className="contactsList" style={{maxHeight:this.state.maxHeightChCont}}>
                                                    <li className="contactsItem"><a href="mailto:martmartmart53@gmail.com" className="itemPagesLinkProj">mail</a></li>
                                                    <li className="contactsItem"><a href="https://www.facebook.com/mart.mai.1/" className="itemPagesLinkProj">facebook</a></li>
                                                    <li className="contactsItem"><a href="https://www.instagram.com/martmaimaimart/" className="itemPagesLinkProj">instagram</a></li>
                                                </ul>
                                            </li>
                                            <li className="itemPages" onClick={()=>this.toggleAbout()}>about</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                        <section className="screen">
                            {!this.state.isOpenAbout &&
                                <div className="wrapperCarriage" style={{opacity:this.state.transparentAbout?'0.0':'1.0'}}>
                                    <div className="helloWrap">
                                        <p className="hello">hello</p>
                                    </div>
                                    <div className="carriage"/>
                                </div>
                            }
                            {this.state.isOpenAbout &&
                                <div className="aboutScreen" style={{opacity:this.state.transparentAbout?'1.0':'0.0'}}>
                                    <div className="aboutScreenWrapper">
                                        <img alt="o/x" className="aboutImg" src={aboutImg}/>
                                        <p className="textMarquee">© 2020 DMITRY KORNIENKO. All rights reserved.</p>
                                    </div>

                                </div>
                            }


                        </section>
                    </div>
                    <div className="wrapper">
                        <div className="aboutContainer">
                            <p className="name">about::LANDSCAPE_0x1</p>
                            <div className="rect"/>
                            {/*<div className="rectWrap">*/}
                        </div>


                    </div>
                    <p className="text"> _
                    </p>
                    <div className="bottomLine">
                        <RectTimeline offset={this.state.persentOffset} time={this.state.durationVideo}/>

                        {/*<div className="borderRect" style={styles}>*/}
                    </div>
                    <Marquee style={markqueeStyle} gradient={false} speed={this.state.durationVideo} direction={"left"}>
                        <p className="textMarquee">The Landscape_0x1 project is reimagining the principle of creating iso-curves which is used in cartography
                            iso-curves help model the landscape for surface analysis with precision, which plays a key role in modelling structures.
                            The technique developed for the project is not precise in the same way that techniques used by cartographers or geodesists are using Lidar and other technologies.
                            Landscape_0x1 is an attempt to reimagine and automate the processes of scanning and retrieving data in real time for landscapes of any level of complexity or location;
                            the source of the data— satellites, drones or planes— has very little influence over the process. Further development of the project will lie in the field of
                            neural-network and 3D-reconstructions when, based on a singular 2D-image, a 3D-model is reconstructed.
                            The same project can be developed in the  area of transport navigation, aircraft navigation, water orientation, etc.
                            The goal of the project is not just reimagining technology, but also reflecting on the changing face of the landscape of our planet.
                            The main narratives of these reflections are the presence and absence of humans in the landscape.  In our presence: the changes in the environment,
                            mining and retrieving of the planet’s natural resources, settling in new territories and further urbanization. In the absence of humans:
                            the development of landscape through the forces of nature.</p>
                    </Marquee>
                </div>


                <div className="bcg">
                    <video onTimeUpdate={(e)=>{this.handleVideoMounted(e)}} className="video" playsInline autoPlay loop muted >
                        <source src={vid} type="video/mp4"/>
                    </video>
                </div>

            </div>

        );
    }
}



export {Main};
