import React from 'react';

import './App.css';
import './main.css';
import {Route, Routes, BrowserRouter as Router} from 'react-router-dom'
import * as Main from './main';
import * as FirstWork from './pages/firstWork';
import * as SecondWork from './pages/secondWork';
import * as ThirdWork from './pages/thirdWork';
import * as CV from './pages/cv'
import * as notFound from './pages/404'
function App() {
  return (
      <Router>
        <Routes>
            <Route path = "/" Component={Main.Main}/>
            <Route path = "/landscape" Component={FirstWork.FirstWork}/>
            <Route path = "/breathe" Component={SecondWork.SecondWork}/>
            <Route path = "/intervals2023" Component={ThirdWork.ThirdWork}/>
            <Route path = "/cv" Component={CV.Cv}/>
            <Route path = "*" Сomponent={notFound.NotFound}/>
        </Routes>
      </Router>
  );
}

export default App;
