import React, { Component } from "react";
import './modal.css'
import '../media.css'
import PropTypes from 'prop-types'
import Portal from "./portal";






const Modal=({title,isOpen,onCancel,children,opacity, display,next,previous,isNew,textChild})=>{

    let classNames = isOpen?"modalOverlay":"modalOverlayOut";
    // let isNew = children!==children+1;


    return(
        <>
            { isOpen &&
                <Portal>
                    <div className={classNames} style={{opacity:opacity,display:display}}>
                        {isNew&&
                            <div className="modalWindow"  style={{opacity:opacity,display:display}}>
                                <div className="modalHeader">
                                    <div className="modalTitle">{title}</div>
                                    <button className="buttonCloseModal" title="name" onClick={onCancel}>
                                        <svg id="0f522b64-45d3-4133-afef-270788022991" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><title>close</title><polygon points="274.84 250.15 175.4 150.92 300.4 25.66 250.98 25.84 150.63 126.2 24.17 0 24.35 49.43 125.88 150.95 0.34 276.51 50.25 276.33 150.65 175.72 275.02 300.07 274.84 250.15" fill="#fff"/></svg>
                                    </button>
                                </div>
                                <div className="modalBody" style={{opacity:opacity}}>

                                    <img src={children} alt = "img"/>


                                    {/*<img src={children} style={{opacity:opacity}} alt = "img"/>*/}

                                    <button className="buttonPrev" onClick={previous}>
                                        <svg id="d78a3f2d-7a25-460a-bbf7-c268a91f7c6b" data-name="b78cb7c2-e097-4520-b36e-6a4c6d947ead" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><title>prev</title><path d="M181.56,273.72,232.06,299c5.69,2.82,11-4.51,6.47-9l-136-136v0a5.58,5.58,0,0,1,0-7.9l136-136c4.53-4.52-.78-11.85-6.47-9l-50.5,25.33a5.52,5.52,0,0,0-1.45,1L61.4,146.13h0a5.59,5.59,0,0,0,0,7.91L180.11,272.72A5.77,5.77,0,0,0,181.56,273.72Z" fill="#fff"/></svg>
                                    </button>
                                    <button className="buttonNext" onClick={next}>
                                        <svg id="06a43bbf-5922-46c6-aa85-88940d67c170" data-name="b78cb7c2-e097-4520-b36e-6a4c6d947ead" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><title>next</title><path d="M119.88,272.72,238.59,154.05a5.59,5.59,0,0,0,0-7.91h0L119.88,27.38a5.52,5.52,0,0,0-1.45-1L67.93,1.05c-5.69-2.85-11,4.48-6.47,9l136,136a5.58,5.58,0,0,1,0,7.9v0l-136,136c-4.5,4.49.78,11.82,6.47,9l50.5-25.25A5.77,5.77,0,0,0,119.88,272.72Z" fill="#fff"/></svg>
                                    </button>

                                </div>
                                <div className="modalFooter">
                                    <div className="wrapModal">
                                        <p className="modalInfo">{title}/{textChild} by dmitry kornienko</p>
                                    </div>
                                    {/*<button onClick={onCancel}>cancel</button>*/}
                                </div>
                            </div>
                        }

                    </div>
                </Portal>
            }
        </>
    );

};

Modal.propTypes = {
    title: PropTypes.string,
    isOpen:PropTypes.bool,
    onCancel: PropTypes.func,
    opacity: PropTypes.number,
    children: PropTypes.node
};

Modal.defaultProps ={
    title:"Modal title",
    isOpen:false,
    onCancel:()=>{},
    opacity:1.0,
    children: null
};

export default Modal;
