import React from 'react';
import '../main.css';
import './project.css';
import '../media.css'

import {Link} from 'react-router-dom'

import Preloader from "../preloader";
import offset from "../rectTimeLine";
import aboutImg from "../img_screen/ox.png";

class Cv extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            maxHeight: 0,
            maxHeightChCont:0,
            maxHeightChProj:0,
            isToggleOn:false,
            toggleChild:false,
            toggleChildCont: false,
            deployList: false,
            color:"white",
            overflow: null,
            count:0,
            countP: 0,
            countC: 0,
            rotate: 0,
            currentTimeVideo: 0.0,
            durationVideo:0.0,
            isLoad: false,
            persentOffset:0,
            searchResult:[],
            searchResultLinks:[],
            opacitySearchList: 0.0,
            transparent:true,
            transparentMenu: true,
            transparentAbout: false,
            isOpenAbout: false

        };

        this.togglePanel = this.togglePanel.bind(this);
        this.toggleChildPanelProj = this.toggleChildPanelProj.bind(this);
        this.toggleChildPanelCont = this.toggleChildPanelCont.bind(this);
        this.deployListOnClick = this.deployListOnClick.bind(this);



    }

    deployListOnClick = (e) => {
        //+++++++++++++++++++++++++++delete++++++++++++++++++++
        //this.setState({deployList:!this.state.deployList});
        //e.preventDefault();
        this.setState((state)=>{
            return{
                deployList:!state.deployList,
            }
        });



        /*this.setState(prevState => ({
            deployList: !prevState.deployList
        }));*/

        const el = document.getElementsByClassName("itemPages");
        const el2 = document.getElementsByClassName("projectsItem");
        const el3 = document.getElementsByClassName("contactsItem");
        let offsetTemp = (el[0].scrollHeight*el.length)+(el2[0].scrollHeight*el2.length)+(el3[0].scrollHeight*el3.length);
        //console.log(offsetTemp)
        //let num = el.length+el2.length+el3.length+1;
        //const offset = e.target.scrollHeight*num;
        if(this.state.deployList){
            this.setState({
                maxHeight:0,
                rotate:0+"deg"
            });
            setTimeout(() => {
                this.setState({ transparentMenu: true });
            }, 200);

        }else{
            this.setState((state)=>{
                return{
                    maxHeight: (state.maxHeight+offsetTemp),
                    rotate:180+"deg"
                }
            });
            setTimeout(() => {
                this.setState({ transparentMenu: false });
            }, 200);
        }



        //console.log("offset: " + offset);
    };

    togglePanel(e){
        this.setState({isToggleOn:!this.state.isToggleOn});
    }


    toggleChildPanelProj = (e) => {
        //+++++++++++++++++++++++++++delete++++++++++++++++++++
        //this.setState({toggleChild:!this.state.toggleChild});

        this.setState((state)=>{
            return{
                toggleChild:!state.toggleChild,
            }
        });
        const el = document.getElementsByClassName("projectsItem");
        const offset = e.target.scrollHeight*el.length;

        if(this.state.toggleChild){
            this.setState({maxHeightChProj:null});
        }else if(!this.state.toggleChild){
            this.setState({maxHeightChProj:offset+"px"});
        }
    };

    toggleChildPanelCont = (e) => {
        //+++++++++++++++++++++++++++delete++++++++++++++++++++
        //this.setState({toggleChildCont:!this.state.toggleChildCont});
        this.setState((state)=>{
            return{
                toggleChildCont:!state.toggleChildCont,
            }
        });
        const el = document.getElementsByClassName("contactsItem");
        const offset = e.target.scrollHeight*el.length;



        if(this.state.toggleChildCont){
            this.setState({maxHeightChCont:null});
        }else{
            this.setState({maxHeightChCont:offset+"px"});
        }


    };



    handleVideoMounted =(e) => {

        let duration = e.target.duration;
        let currentTimeVariable = e.target.currentTime;

        let remap = ((1/duration)*currentTimeVariable)*100;


        let elem = document.getElementsByClassName("bottomLine")[0]
        let vidElem = document.getElementsByClassName("video")[0]
        let widthElem = elem.offsetWidth;
        let lengthRect = 30;
        /*let a = false;
        vidElem.oncanplay = function() {
            a = true;
        };*/

        let widthWithoutElem = widthElem-lengthRect;
        let percent = (widthWithoutElem*100)/widthElem;


        //console.log(remap);
        this.setState({currentTimeVideo:remap});
        this.setState({durationVideo:duration});
        this.setState({persentOffset:percent});
        if(remap>=percent){
            this.setState({currentTimeVideo:0});
            //this.setState({durationVideo:0});
            this.setState({persentOffset:0});
            //this.setState({currentTimeVideo:0});
        }
        offset(remap);

    };



    handleOnLoad=(e)=>{
        //console.log(e.target.played.length)
        this.setState({isLoad:!this.state.isLoad})

    };
    binarySearch=(arr, key)=>{
        let start = 0;
        let end = arr.length-1;

        while(start <= end){
            let middle = Math.floor((start+end)/2);
            if(arr[middle] === key) return middle;
            if(arr[middle] < key){
                start = middle+1;
            }else{
                end = middle-1;
            }
        }
        return -1;
    }
    componentDidMount() {

    }
    searchingList=(e)=>{
        const elem = document.getElementsByClassName("pages")[0];
        let inputValue = e.target.value.toLowerCase();

        const itemsLength = elem.childNodes.length
        let arrPages = [];
        let linkArr = [];
        let sectionArr = [];

        if(inputValue!==''){
            setTimeout(() => {
                this.setState({ transparent: false });
            }, 200);
        }else{
            setTimeout(() => {
                this.setState({ transparent: true });
            }, 200);
        }

        for(let i = 0; i < itemsLength;i++){
            arrPages.push(elem.childNodes.item(i));
            if(arrPages[i].hasChildNodes()){
                linkArr.push(arrPages[i].lastChild.href);
                sectionArr.push(arrPages[i].lastChild.text)
            }

            if(arrPages[i].childNodes.length > 1) {
                if(arrPages[i].childNodes.item(1).hasChildNodes()){
                    let size = arrPages[i].lastChild.childElementCount;
                    for(let j = 0; j < size;j++){
                        linkArr.push(arrPages[i].lastChild.childNodes.item(j).childNodes.item(0).href);
                        sectionArr.push(arrPages[i].lastChild.childNodes.item(j).childNodes.item(0).text)
                    }
                }
            }
        }

        const outLinkArr = linkArr.filter(element => {
            return element !== undefined;
        });
        const outSectionArr = sectionArr.filter(element => {
            return element !== undefined;
        });
        let list = new Map();
        for(let i = 0; i < elem.childNodes.length;i++){
            list.set(outSectionArr[i],outLinkArr[i]);
        }
        const searchFilter = outSectionArr.filter((element,index) =>{
            if(element.includes(inputValue) && inputValue !== ''){
                return element;
            }
        });

        let linksTemp = [];
        for(let el of searchFilter){
            linksTemp.push(list.get(el));
        }

        this.setState({
            searchResult: searchFilter,
            searchResultLinks: linksTemp
        });


        //console.log(this.state.searchResultLinks.get(inputValue))
        //console.log(this.state.searchResultLinks)

    }
    toggleAbout=()=>{
        this.setState((state)=>{
            return{
                isOpenAbout:!state.isOpenAbout,
            }
        });
        if(this.state.isOpenAbout){
            setTimeout(() => {
                this.setState({ transparentAbout: false });
            }, 200);
        }else{
            setTimeout(() => {
                this.setState({ transparentAbout: true });
            }, 200);
        }

    }

    render(){
        const markqueeStyle = {
            backgroundColor:"rgba(255, 255, 255, 0.0)",
            margin:"20px 0 0 0",
            //borderBottom: "1px solid #ffffff",
            //borderTop: "1px solid #ffffff"
        }
        return(
            <div className ="main">
                <Preloader/>
                <div className="mainWrap">
                    <div className="wrapper">
                        <h1 className="header">DMITRY KORNIENKO</h1>
                        <div className="searchContainer">
                            {/*<p className="search">search</p>*/}
                            <form>
                                <input onChange={(e)=>this.searchingList(e)} autoComplete="off" className="search" type="text" name="itemNavigation" placeholder="find something.."/>
                            </form>
                            <div className="searchList" >
                                {
                                    this.state.searchResult.map((item,key)=>{
                                        return <a key={key} style={{opacity: this.state.transparent ? '0.0' : '1.0' }}  href={this.state.searchResultLinks[key]} className="searchRef">{item}</a>;
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="wrapper">
                        <nav className="navigation">
                            <div className="wrapper">
                                <button className="arrow"  onClick={(e)=>this.deployListOnClick(e)} value={!this.state.deployList} style ={{transform: `rotate(${this.state.rotate})`}}>
                                    <svg id="39d3585c-6493-4962-8551-4e8e9da4fd73" data-name="Layer 2"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                        <title>Untitled-2</title>
                                        <path d="M27.33,119.88,146,238.59a5.59,5.59,0,0,0,7.92,0L272.67,119.88a5.38,5.38,0,0,0,1.05-1.45L299,67.93c2.85-5.69-4.48-11-9-6.47l-136,136a5.59,5.59,0,0,1-7.92,0L10,61.46c-4.49-4.5-11.82.78-9,6.47l25.25,50.5A5.38,5.38,0,0,0,27.33,119.88Z" fill="#fff"/>
                                    </svg>

                                </button>
                                <ul className="listNavigation" >
                                    <li className= "itemNavigation">
                                        menu
                                        <ul className="pages" style={{maxHeight:this.state.maxHeight+"px",opacity: this.state.transparentMenu ? '0.0' : '1.0' }}>
                                            <li className="itemPages"><Link to="/" className="itemPagesLink">home</Link></li>
                                            <li className="itemPages" onClick={(e)=>this.toggleChildPanelProj(e)} >projects/
                                                <ul className="projectsList" style={{maxHeight:this.state.maxHeightChProj}}>
                                                    <li className="projectsItem">
                                                        <Link to="/landscape" className="itemPagesLinkProj">landscape_0x1</Link>
                                                    </li>
                                                    <li className="projectsItem">
                                                        <Link to="/breathe" className="itemPagesLinkProj">breathe</Link>
                                                    </li>
                                                    <li className="projectsItem">
                                                        <Link to="/intervals2023" className="itemPagesLinkProj">intervals2023</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="itemPages"><Link to="/cv" className="itemPagesLink">cv</Link></li>
                                            <li className="itemPages" onClick={(e)=>this.toggleChildPanelCont(e)}>contacts/
                                                <ul className="contactsList" style={{maxHeight:this.state.maxHeightChCont}}>
                                                    <li className="contactsItem"><a href="mailto:martmartmart53@gmail.com" className="itemPagesLinkProj">mail</a></li>
                                                    <li className="contactsItem"><a href="https://www.facebook.com/mart.mai.1/" className="itemPagesLinkProj">facebook</a></li>
                                                    <li className="contactsItem"><a href="https://www.instagram.com/martmaimaimart/" className="itemPagesLinkProj">instagram</a></li>
                                                </ul>
                                            </li>
                                            <li className="itemPages" onClick={()=>this.toggleAbout()}>about</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                        <section className="screen">
                            {!this.state.isOpenAbout &&
                                <div className="wrapperCarriage" style={{opacity:this.state.transparentAbout?'0.0':'1.0'}}>
                                    <div className="helloWrap">
                                        {/*<p className="hello">hello</p>*/}
                                        <p className="cvText"><h3 className="headerCVText">[Mapping Festival 2051]</h3>
                                            worked on a project with studio Frederik De Wilde/HYPERMAINER/[2020-2021]</p>
                                        <p className="cvText"><h3 className="headerCVText">[Intervals Festival]</h3>
                                            took part in the project with ARKTK BERKUT[2021]</p>
                                        <p className="cvText"><h3 className="headerCVText">[TSEH]</h3>
                                            exhibition_mediatorium/breathe(project)[2023]</p>
                                        <p className="cvText"><h3 className="headerCVText">[Intervals Festival 2023]</h3>
                                            mapping proj on building</p>
                                        <p className="cvText"><h3 className="headerCVText">[Publications]</h3>
                                            <a href="https://www.stirworld.com/see-features-the-case-for-a-new-media-canon-placing-the-works-of-dmitry-kornienko"
                                               className="cvLinks">STIR_WORLD/about my work___[https://www.stirworld.com/see-features-the-case-for-a-new-media-canon-placing-the-works-of-dmitry-kornienko][2021]</a></p>
                                    </div>
                                    {/*<div className="carriage"/>*/}
                                </div>
                            }
                            {this.state.isOpenAbout &&
                                <div className="aboutScreen" style={{opacity:this.state.transparentAbout?'1.0':'0.0'}}>
                                    <div className="aboutScreenWrapper">
                                        <img alt="o/x" className="aboutImg" src={aboutImg}/>
                                        <p className="textMarquee">© 2020 DMITRY KORNIENKO. All rights reserved.</p>
                                    </div>
                                </div>
                            }
                        </section>
                    </div>
                    <div className="wrapper">
                        <div className="offsetWrap">
                            <p className="textProj">page_CV/info </p>
                            <div className="rectProj"></div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}



export {Cv};
