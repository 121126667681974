import React from 'react';
import './404.css'
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'

class NotFound extends React.Component{
    constructor(props){
        super(props);
    }

    componentDidMount() {
        const el = document.getElementsByClassName("")
    }
    componentWillUnmount() {
    }

    render() {
        return(
            <div className ="main404">
                <div className="mainWrap404">
                    <div className="wrapper">
                        <h1 className="header">DMITRY KORNIENKO</h1>
                        <div className="searchContainer">
                            <form>
                                <input className="search" type="text" name="itemNavigation" placeholder="search wip...."/>
                            </form>
                        </div>
                    </div>
                    <section className="screen404">
                        <div className="wrapper">
                            <p className="header404">404</p>
                            <div className="carriage404"/>
                        </div>
                        <div className="wrapper">
                            <p className="text404">not found</p>
                            <Link to="/" className="linkHome404">back_to_home</Link>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export {NotFound}